import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { scroller } from "react-scroll";
import Section from "./Section";
import { useDarkMode } from "./../util/theme";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
    cursor: 'pointer',
  },
  drawerList: {
    backgroundColor: 'white',
    width: 210,
  },
  spacer: {
    backgroundColor: 'white',
    flexGrow: 1,
  },
  headerLink: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    color: '#695DFF',
    textDecoration: 'none',
    margin: theme.spacing(1),
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      color: 'darkblue',
    }
  },
  connectButton: {
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 600,
    backgroundColor: '#695DFF',
    color: 'white',
    padding: theme.spacing(1, 3),
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'darkblue',
    }
  },
  appBar: {
    backgroundColor: 'white',
    height: 87,
    display: "flex",
    justifyContent: "center",
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  listItemText: {
    color: '#695DFF',
    textDecoration: 'none',
    textTransform: 'none',
    '&:hover': {
      color: 'darkblue',
    }
  }
}));

function Navbar(props) {
  const classes = useStyles();
  const location = useLocation();

  const darkMode = useDarkMode();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  useEffect(() => {
    const handleHashChange = () => {
      const sectionId = window.location.hash.replace("#", "");
      const offset = -87;
      if (sectionId) {
        setTimeout(() => {
          scroller.scrollTo(sectionId, {
            smooth: true,
            duration: 500,
            offset: offset,
          });
        }, 100);
      }
    };

    window.addEventListener("hashchange", handleHashChange, false);
    window.addEventListener("load", handleHashChange, false);

    if (window.location.hash) {
      handleHashChange();
    }

    return () => {
      window.removeEventListener("hashchange", handleHashChange, false);
      window.removeEventListener("load", handleHashChange, false);
    };
  }, []);

  const handleLinkClick = (sectionId) => {
    const offset = -87;
    if (location.pathname !== "/") {
      window.location.href = `/#${sectionId}`;
    } else {
      scroller.scrollTo(sectionId, {
        smooth: true,
        duration: 500,
        offset: offset,
      });
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location.pathname !== "/") {
      window.location.href = "/#hero2-section";
    } else {
      scroller.scrollTo("hero2-section", {
        smooth: true,
        duration: 500,
        offset: -87,
      });
    }
  };

  return (
    <Section bgColor={props.color} size="auto">
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Container disableGutters={true}>
          <Toolbar className={classes.toolbar}>
            <RouterLink to="/" className={classes.headerLink} onClick={handleLogoClick}>
              <img src={logo} alt="TIPON" className={classes.logo} />
            </RouterLink>
            <div className={classes.spacer} />
            <Hidden mdUp={true} implementation="css">
              <IconButton 
                onClick={() => {
                  setDrawerOpen(true);
                }}
                 color="primary"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown={true} implementation="css">
              <Button onClick={() => handleLinkClick("hero2-section")} className={classes.headerLink}>
                Home
              </Button>
              <Button onClick={() => handleLinkClick("features-section")} className={classes.headerLink}>
                Features
              </Button>
              <Button onClick={() => handleLinkClick("how-section")} className={classes.headerLink}>
                How it works
              </Button>
              <Button onClick={() => handleLinkClick("benefits-section")} className={classes.headerLink}>
                Benefits
              </Button>
              <Button onClick={() => handleLinkClick("help-section")} className={classes.headerLink}>
                Help
              </Button>
              <Box component="span" ml={1}>
                <Button
                  onClick={() => handleLinkClick("connect-section")}
                  variant="contained"
                  className={classes.connectButton}
                >
                  Connect
                </Button>
              </Box>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItem button onClick={() => handleLinkClick("hero2-section")}>
            <ListItemText primaryTypographyProps={{ className: classes.listItemText }}>Home</ListItemText>
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("features-section")}>
            <ListItemText primaryTypographyProps={{ className: classes.listItemText }}>Features</ListItemText>
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("how-section")}>
            <ListItemText primaryTypographyProps={{ className: classes.listItemText }}>How it works</ListItemText>
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("benefits-section")}>
            <ListItemText primaryTypographyProps={{ className: classes.listItemText }}>Benefits</ListItemText>
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("help-section")}>
            <ListItemText primaryTypographyProps={{ className: classes.listItemText }}>Help</ListItemText>
          </ListItem>
          <ListItem button onClick={() => handleLinkClick("connect-section")}>
            <Button
              variant="contained"
              className={classes.connectButton}
            >
              Connect
            </Button>
          </ListItem>
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
