import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import LegalPage from "./legal";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { ThemeProvider } from "./../util/theme";
import GlobalStyle from './../GlobalStyle';

function App(props) {
  const navbarStyle = {
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 1000,
  };

  return (
  <>
    <GlobalStyle />
    <ThemeProvider>
      <Router>
        <div>
          <div style={navbarStyle}>
            <Navbar
              logo="/horizontal-logo.svg"
              logoInverted="/horizontal-logo.svg"
            />
          </div>

          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/legal/:section" component={LegalPage} />
            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bgColor="white"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            description="QR & NFC Tipping"
            copyright={`© ${new Date().getFullYear()} Tipon Inc.`}
            logo="/horizontal-logo.svg"
            logoInverted="/horizontal-logo.svg"
            sticky={true}
          />
        </div>
      </Router>
    </ThemeProvider>
  </>
  );
}

export default App;
