import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionHeader: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(6),
  },
  sectionTitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  featureImage: {
    backgroundColor: 'white',
    width: '100px', // Adjust size as needed
    height: '100px',
  },
  title1: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.8rem',
    fontWeight: 500,
    color: '#8A58E0',
    marginBottom: theme.spacing(3),
  },
  text: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '0.9rem',
    fontWeight: 300,
    color: '#000000',
    marginBottom: theme.spacing(0),
  },
}));

function BenefitsSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Increased Earnings",
      subtitle: "Digital tips can boost your earnings by up to 30%. By offering an easy, cashless tipping option through QR Codes and NFC Tags, customers are more likely to tip, increasing your overall income effortlessly.",
      image: "/increased.png",
    },
    {
      title: "Convenience and Usability",
      subtitle: "Using QR/NFC payments is simple and convenient for both you and your customers. Customers can quickly scan a QR Code or tap an NFC Tag to complete a transaction, making the payment process seamless and efficient.",
      image: "/convenience.png",
    },
    {
      title: "Real-Time Feedback",
      subtitle: "Instant customer feedback is crucial for improving service quality. With Tipon, you receive immediate feedback after each transaction, allowing you to make data-driven improvements and enhance customer satisfaction.",
      image: "/realtime.png",
    },
    {
      title: "Reliability and Security",
      subtitle: "Tipon prioritizes the security of your transactions and data. We use advanced encryption and security protocols to ensure that all payment information is protected, giving you peace of mind with every transaction.",
      image: "/security.png",
    },
  ];

  return (
    <Section id="benefits-section" 
      bgColor={"white"}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center">
          <Typography className={classes.sectionTitle}>Key benefits of using Tipon</Typography>
        </Box>
        <Grid container={true} justifyContent="center" spacing={6}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} md={3} key={index}>
              <Box textAlign="center">
                <Box>
                  <img src={item.image} alt={item.title} className={classes.featureImage} />
                </Box>
                <Typography variant="h5" gutterBottom={true} className={classes.title1}>
                  {item.title}
                </Typography>
                <Box mt={3}>
                  <Typography variant="subtitle1" className={classes.text}>{item.subtitle}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default BenefitsSection;
