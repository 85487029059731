import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { ThemeProvider } from "./../util/theme";
import GlobalStyle from './../GlobalStyle';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'white',
    padding: `0 ${theme.spacing(3)}px`,
  },
  sectionTitle: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '2.1rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: theme.spacing(6),
  },
  question: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#000000',
  },
  answer: {
    backgroundColor: 'white',
    fontFamily: 'Onest, sans-serif',
    fontSize: '1.2rem',
    fontWeight: 300,
    color: '#000000',
  },
}));

function HelpSection(props) {
  const classes = useStyles();
  
  const items = [{
    question: "What is Tipon?",
    answer: "Tipon is a cashless tipping system that allows customers to leave tips using QR Codes or NFC Tags. It is designed for the hospitality and service industries, providing an easy and convenient way for customers to tip service providers and leave feedback. The system integrates seamlessly with Stripe for secure payment processing.",
  }, {
    question: "How does Tipon work?",
    answer: "To start accepting tips with Tipon, employees or business owners need to register on the platform and link their bank accounts. After registration, users generate a QR Code or link an NFC Tag. Customers can then use their smartphones to interact with these codes or tags, leading them to a payment page where they can leave a tip and feedback. Employees receive notifications of the tips, and businesses get valuable customer feedback.",
  }, {
    question: "What industries can benefit from using Tipon?",
    answer: "Tipon is ideal for various service-oriented businesses, including beauty salons, hair salons, nail/brow salons, small and family-run hotels, tattoo parlors, massage and fitness clubs, and grooming salons for pets. It is particularly beneficial in environments where tipping is customary but often neglected due to the lack of cash or convenient payment methods.",
  }, {
    question: "Is it secure to use Tipon for transactions?",
    answer: "Yes, Tipon prioritizes security by integrating with Stripe, a trusted and secure payment processing platform. This ensures that all transactions are safe and protected. Additionally, Tipon provides real-time financial management and notifications, offering transparency and security for both customers and service providers.",
  }, {
    question: "How can businesses get started with Tipon?",
    answer: "Businesses can get started with Tipon through the Tipon mobile app, available on the App Store and Google Play. After creating an account and linking it to Stripe, businesses can create service links using QR Codes and NFC Tags. These links can be displayed in their establishments, allowing customers to tip easily.",
  }, {
    question: "What are the benefits of using Tipon for employees and businesses?",
    answer: "For employees, Tipon offers a transparent and equitable tipping system, providing immediate recognition and boosting job satisfaction. For businesses, it enhances customer engagement, offers valuable feedback, and helps improve service quality through data-driven insights. The ease of use and real-time financial management also streamline operations, making Tipon a valuable tool for both employees and businesses.",
  },
  ];

  return (
  <>
    <GlobalStyle />
    <ThemeProvider>
    <Section id="help-section" 
      bgColor={"white"}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className={classes.container}>
        <Box textAlign="center">
          <Typography className={classes.sectionTitle}>Frequently Asked Questions</Typography>
        </Box>
        <Box pt={2}>
          {items.map((item, index) => (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq-panel-${index}`}
              >
                <Typography className={classes.question}>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails id={`faq-panel-${index}`}>
                <Typography className={classes.answer}>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Section>
    </ThemeProvider>
  </>
  );
}

export default HelpSection;
